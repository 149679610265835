<template>
    <el-dialog :title="`${increase.id == 0 ? '添加':'编辑'}白名单`"
               width="600px"
               v-dialogdrag
               :visible.sync="increase.type"
               :close-on-click-modal="false"
               :show-close="false"
               :before-close="handleClose">
      <avue-form :option="option" v-model="form" ref="form">
        <template slot="phone">
          <el-input type="tel" maxlength="11" style="height:32px" placeholder="请输入手机号" v-model="form.phone" clearable
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"></el-input>
        </template>
      </avue-form>
        <span slot="footer" class="dialog-footer">
              <el-button size="small" @click="handleClose">取 消</el-button>
              <el-button
                  type="primary"
                  size="small"
                  @click="handleSubmit(form)">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { whiteView,whiteAdd,whiteUpdate } from "@/api/platform/white";
    export default {
        name: "increase",
        props: {
            increase: Object
        },
        data(){
            return{
                form: {},
                option: {
                    labelWidth: 120,
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                      {
                        label:'手机号',
                        prop:'phone',
                        type: 'input',
                        slot: true,
                        span: 20,
                        rules: [{
                          required: true,
                          message: "输入手机号",
                          trigger: "blur"
                        }]
                      },{
                        label:'密码',
                        prop:'password',
                        maxlength: 10,
                        span: 20,
                        placeholder: '不设置则默认a123456',
                        rules: [{
                          message: "不设置则默认a123456",
                          trigger: "blur"
                        }]
                      },{
                        label:'描述',
                        prop:'whiteDesc',
                        maxlength: 50,
                        span: 20,
                        placeholder: '请输入描述',
                        rules: [{
                          message: "请输入描述",
                          trigger: "blur"
                        }]
                      },
                    ]
                }
            }
        },
        mounted() {
            if(this.increase.id != ''){
                this.GET_identityView()
            }
        },
        methods: {
            handleSubmit(form){
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.increase.id == ''){
                            this.GET_identityAdd(form,done);
                        }else{
                            this.GET_identityUpdate(form,done);
                        }
                    }
                })
            },
            GET_identityAdd(form,done){
                whiteAdd({
                    phone: form.phone,
                    password: form.password,
                    whiteDesc: form.whiteDesc,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeIdentityIncrease', true);
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_identityUpdate(form,done){
                whiteUpdate({
                    id: form.id,
                    phone: form.phone,
                    password: form.password,
                    whiteDesc: form.whiteDesc,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeIdentityIncrease', true);
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_identityView(){
                whiteView(this.increase.id).then(res=>{
                    // console.log(res)
                    if(res.code === 200){
                        this.form = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            handleClose(){
                this.$root.eventHub.$emit('changeIdentityIncrease', false);
            }
        }
    }
</script>

<style scoped>

</style>
