<template>
    <div class="main-content">
        <pagesHeader title="白名单管理" />
        <Search @changeSearch="changeSearch" @changeIncrease="changeIncrease"/>
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
            <template slot="menu" slot-scope="{ row }">
                <el-button size="small" type="text" @click="changeEdit(row.id)">编辑</el-button>
                <el-button size="small" type="text" @click="changeDelete(row.id,row.phone)">删除</el-button>
            </template>
        </avue-crud>
        <Increase v-if="increase.type" :increase="increase"/>
    </div>
</template>

<script>
    import column from "./column";
    import Search from "./search";
    import Increase from "./increase";
    import { whiteList,whiteDelete } from "@/api/platform/white";
    export default {
        name: "white",
        components: {
            Search,
            Increase
        },
        data(){
            return{
                increase: {
                    type: false,
                    id: ''
                },
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    menuWidth: 200,
                    index: true,
                    indexLabel: '序号',
                    indexWidth: 100,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeIdentityIncrease', (n)=>{
                this.increase =  {
                    type: false,
                    id: ''
                };
                if(n){
                    this.GET_identityList();
                }
            })
        },
        methods: {
            onLoad(){
                this.GET_identityList();
            },
            GET_identityList(){
                whiteList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    phone: this.search.phone,
                }).then(res=>{
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeEdit(id){
                // console.log('编辑',id)
                this.increase.type = true;
                this.increase.id = id;
            },
            changeDelete(id,phone){
                // console.log('删除',id)
                this.$confirm('确定删除用户 '+phone+' 的数据吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    whiteDelete(id).then(res => {
                        if(res.code === 200){
                            this.$message.success('删除成功!')
                            this.GET_identityList()
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err =>{
                        this.$message.error(err.msg)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除!')
                });
            },
            changeSearch(e){
                // console.log(e)
                this.search = e;this.pageSync.currentPage = 1;
                this.GET_identityList();
            },
            changeIncrease(){
                this.increase.type = true;
                this.increase.id = '';
            }
        }
    }
</script>

<style scoped>

</style>
