import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
// 首页
import index from '@/pages/index/index';

// view 内容
// index首页
import indexHome from '@/view/index/index';

//平台管理
import whiteManage from '@/view/platform/white/white'
import platformUser from '@/view/platform/user/user'
import platformPrivacy from '@/view/platform/privacy/privacy'

//产品管理
import productClassify from '@/view/product/classify/classify';
import productManage from '@/view/product/manage/manage';
import productManageIncreased from '@/view/product/manage/increased';
import productManageRelatedGoods from '@/view/product/manage/relatedGoods';


// system 系统管理
import systemUser from '@/view/system/user';
import systemRole from '@/view/system/role';

export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/',
			component: index,
			children: [
				{
					path: '/',
					name: 'indexHome',
					component: indexHome
				},
				// ------------------------------------------------------ 系统管理
				{
					path: '/system/user',
					name: 'systemUser',
					component: systemUser
				},
				{
					path: '/system/role',
					name: 'role',
					component: systemRole
				},
				// ------------------------------------------------------ 产品管理
				{
					path: '/product/classify',
					name: 'productClassify',
					component: productClassify
				},
				{
					path: '/product/manage',
					name: 'productManage',
					component: productManage
				},
				{
					path: '/product/manage/increased/:id',
					name: 'productManageIncreased',
					component: productManageIncreased
				},
				{
					path: '/product/manage/relatedGoods/:id',
					name: 'productManageRelatedGoods',
					component: productManageRelatedGoods
				},
				// ------------------------------------------------------ 平台管理
				{
					path: '/platform/white',
					name: 'platformWhite',
					component: whiteManage
				},
				{
					path: '/platform/user',
					name: 'platformUser',
					component: platformUser
				},
				{
					path: '/platform/privacy',
					name: 'platformPrivacy',
					component: platformPrivacy
				}
			]
		}
	]
})
