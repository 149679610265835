<template>
    <el-dialog title=""
               width="400px"
               v-dialogdrag
               :visible.sync="templateInfo.type"
               :close-on-click-modal="false"
               :before-close="handleClose">
        <div class="submit">
            <el-button class="submit_one" type="primary" @click="clickExport">下载模板</el-button>
            <el-upload
                    :headers="headers"
                    :action="action"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :on-error="handleAvatarError"
                    :before-upload="beforeUpload"
                    multiple
                    type="primary"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    :limit="3"
                    :file-list="fileList"
                    style="margin:0 20px 10px;"
            >
                <el-button type="info" style="background: #f59a23;border: none;height: 40px;">批量导入</el-button>
            </el-upload>
        </div>
    </el-dialog>
</template>

<script>
    import { baseUrl } from '@/config/public.js';
    export default {
        props: {
            templateInfo: Object
        },
        data(){
            return{
                action: `${baseUrl}/web/product/manage/import`,
                headers: {
                    Authorization: this.$store.state.user.Token
                },
                fileList: []
            }
        },
        methods: {
            clickExport(){
                this.$confirm("是否下载模板?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    window.open(`/api/web/product/manage/export`,'_self');
                });
            },
            // 文件校验
            beforeUpload(file) {
                // 文件类型
                const isType = file.type === 'application/vnd.ms-excel';
                const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const fileType = isType || isTypeComputer;
                if (!fileType) {
                    this.$message.error('上传文件只能是xls/xlsx格式！');
                } // 文件大小限制为2M
                const fileLimit = file.size / 1024 / 1024 < 20;
                if (!fileLimit) {
                    this.$message.error('上传文件大小不超过20M！');
                }
                return fileType && fileLimit;
            },
            // 导入成功
            handleAvatarSuccess(res, file) {
                // console.log(res,file)
                if (res.code == 200) {
                    this.$message.success(res.data.title);
                    this.$root.eventHub.$emit('changeTemplate', true);
                  if(res.data.details.length > 0){
                    let text = '';
                    res.data.details.forEach(item=>{
                      text += item
                    })
                    this.$notify({
                      title: '提示',
                      dangerouslyUseHTMLString: true,
                      message: text,
                      duration: 0
                    });
                  }
                } else {
                    this.$message.error(res.msg);
                }
            },
            handleAvatarError(err){
              const e = JSON.parse(err.message);
              this.$message.error(e.msg);
            },
            handleClose(){
                this.$root.eventHub.$emit('changeTemplate', false);
            }
        }
    }
</script>

<style scoped>
.submit{
    display: flex;
    align-items: center;
    justify-content: center;
}
.submit_one{
    margin: 0 20px 10px 20px;
}
</style>
