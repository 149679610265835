<template>
    <div class="main-content">
        <pagesHeader title="产品管理" />
        <Search @changeSearch="changeSearch"></Search>
        <div class="header_button">
            <el-button type="primary" @click="changeIncreased(0)">添加产品</el-button>
            <el-button @click="changeTemplate()">数据批量导入</el-button>
            <el-button @click="clickExport">数据导出</el-button>
        </div>

        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
            <template slot="oneClassifyName" slot-scope="{ row }">
                <div>{{row.oneClassifyName}}{{row.twoClassifyName ? `/${row.twoClassifyName}` : ''}}{{row.threeClassifyName ? `/${row.threeClassifyName}` : ''}}</div>
            </template>

            <template slot="menu" slot-scope="{ row }">
                <el-button size="small" type="text" @click="changeEdit(row.id)">编辑</el-button>
                <el-button size="small" type="text" @click="changeGoods(row.id)">设置关联商品</el-button>
                <el-button size="small" type="text" @click="changeDelete(row.id,row.productModel)">删除</el-button>
            </template>

        </avue-crud>

        <divTemplate v-if="templateInfo.type" :templateInfo="templateInfo"/>
    </div>
</template>

<script>
    import Search from "./search"
    import divTemplate from "./template"
    import column from "./column";
    import { manageList,manageDelete } from "@/api/product/manage"
    export default {
        name: "manage",
        components: {
            Search,
            divTemplate
        },
        data(){
            return{
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    menuWidth: 400,
                    index: true,
                    indexLabel: '序号',
                    indexWidth: 100,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {
                  productModel: '',
                  oneClassifyId: '',
                  twoClassifyId: '',
                  threeClassifyId: ''
                },
                templateInfo: {
                    type: false
                }
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeTemplate', (n)=>{
                this.templateInfo.type = false;
                if(n){
                    this.getManageList();
                }
            })
        },
        methods: {
            onLoad(){
                this.getManageList();
            },
            getManageList(){
                manageList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    productModel: this.search.productModel,
                    oneClassifyId: this.search.oneClassifyId,
                    twoClassifyId: this.search.twoClassifyId,
                    threeClassifyId: this.search.threeClassifyId,
                }).then(res=>{
                    if(res.code == 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeSearch(e){
                // console.log(e)
                this.search = e;
                this.pageSync.currentPage = 1;
                this.getManageList();
            },
            changeIncreased(id){
                this.$router.push({name: 'productManageIncreased',params: { id: id }})
            },
            changeEdit(id){
                // console.log('编辑',id)
                this.$router.push({name: 'productManageIncreased',params: { id: id }})
            },
            changeGoods(id){
                this.$router.push({name: 'productManageRelatedGoods',params: { id: id }})
            },
            changeDelete(id,productModel){
                // console.log('删除',id)
                this.$confirm("是否删除型号"+productModel+"的数据吗?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    manageDelete(id).then(res=>{
                        if(res.code === 200){
                            this.$message.success(res.msg)
                            this.getManageList();
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                    })
                });
            },
            changeTemplate(){
                this.templateInfo.type = true;
            },
            clickExport(){
                this.$confirm("是否导出数据?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    console.log(this.search.productModel)
                    console.log(this.search.oneClassifyId)
                    console.log(this.search.twoClassifyId)
                    console.log(this.search.threeClassifyId)
                    window.open(`/api/web/product/manage/list/export?productModel=${this.search.productModel || ''}&oneClassifyId=${this.search.oneClassifyId || ''}&twoClassifyId=${this.search.twoClassifyId || ''}&threeClassifyId=${this.search.threeClassifyId || ''}`,'_self');
                });
            }
        }
    }
</script>

<style scoped>
.header_button{
    margin-bottom: 20px;
}
</style>
