<template>
    <el-dialog :title="`${classifyIncreased.id == 0 ? '新增':'编辑'}分类`"
               width="600px"
               v-dialogdrag
               :visible.sync="classifyIncreased.type"
               :close-on-click-modal="false"
               :show-close="false"
               :before-close="handleClose">
        <avue-form :option="option" v-model="form" ref="form">
            <template slot="sort">
                <el-input v-model="form.sort"
                          autocomplete="off"
                          type="text"
                          maxlength="6"
                          placeholder="请输入 排序"
                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                          @blur="salaryChange($event,'sort')"/>
            </template>
        </avue-form>
        <span slot="footer" class="dialog-footer">
              <el-button size="small" @click="handleClose">取 消</el-button>
              <el-button type="primary" size="small" @click="handleSubmit(form)">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import column from "./column"
    import { classifyView,classifyAdd,classifyUpdate } from "@/api/product/classify"
    export default {
        name: "increased",
        props: {
            classifyIncreased: Object
        },
        data(){
            return{
                form: {
                    isShow: 1
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.columnIncrease
                }
            }
        },
        mounted() {
            if(this.classifyIncreased.id != 0){
                this.getClassifyView(this.classifyIncreased.id);
            }
        },
        methods: {
            getClassifyView(id){
                classifyView(id).then(res=>{
                    // console.log(res)
                    if(res.code == 200) {
                        this.form = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=> {
                    this.$message.error(err.msg)
                })
            },
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.classifyIncreased.id == 0){
                            this.getClassifyAdd(form,done);
                        }else{
                            this.getClassifyUpdate(form,done);
                        }
                    }
                })
            },
            getClassifyAdd(form,done){
                classifyAdd({
                    "classifyLevel": this.classifyIncreased.classifyLevel,
                    "parentId": this.classifyIncreased.parentId,
                    "isShow": form.isShow,
                    "name": form.name,
                    "sort": form.sort
                }).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeProductClassify', true);
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err =>{
                    this.$message.error(err.msg)
                })
            },
            getClassifyUpdate(form,done){
                classifyUpdate({
                    "id": form.id,
                    "isShow": form.isShow,
                    "name": form.name,
                    "sort": form.sort
                }).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeProductClassify', true);
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err =>{
                    this.$message.error(err.msg)
                })
            },
            handleClose(){
                this.$root.eventHub.$emit('changeProductClassify', false);
            },
        }
    }
</script>

<style scoped>

</style>
