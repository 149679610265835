<template>
    <div class="search">
        <el-input v-model="form.productModel" placeholder="请输入产品型号" class="margin"></el-input>
        <el-cascader :options="options" v-model="value" clearable class="margin" :props="props" placeholder="请选择产品分类" @change="changeCascader"></el-cascader>
        <el-button type="primary" @click="searchQuery" style="margin-bottom: 10px">查询</el-button>
        <el-button type="info" @click="searchReset" style="margin-bottom: 10px">重置</el-button>

    </div>
</template>

<script>
    import { classifyManageList } from "@/api/product/manage"
    export default {
        name: "search",
        data(){
            return{
                form: {
                    productModel: '',
                    oneClassifyId: '',
                    twoClassifyId: '',
                    threeClassifyId: ''
                },
                options: [],
                props: {
                    checkStrictly: true,
                    label: 'name',
                    value: 'id'
                },
                value: []
            }
        },
        mounted() {
            this.getClassifyList();
        },
        methods: {
            getClassifyList(){
              classifyManageList().then(res=>{
                    if(res.code == 200){
                        this.options = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeCascader(e){
                // console.log(e)
                if(e.length > 0){
                    e.forEach((item,index)=>{
                        if(index == 0 && e.length == 1){
                            this.form.oneClassifyId = item;
                            this.form.twoClassifyId = '';
                            this.form.threeClassifyId = '';
                        }else if(index == 1 && e.length == 2){
                            this.form.oneClassifyId = '';
                            this.form.twoClassifyId = item;
                            this.form.threeClassifyId = '';
                        }else if(index == 2 && e.length == 3){
                            this.form.oneClassifyId = '';
                            this.form.twoClassifyId = '';
                            this.form.threeClassifyId = item;
                        }
                    })
                }
            },
            searchQuery(){
              this.form.oneClassifyId=this.value[0]?this.value[0]:''
              this.form.twoClassifyId=this.value[1]?this.value[1]:''
              this.form.threeClassifyId=this.value[2]?this.value[2]:''
                this.$emit('changeSearch',this.form)
            },
            searchReset(){
                this.form = {
                    productModel: '',
                    oneClassifyId: '',
                    twoClassifyId: '',
                    threeClassifyId: ''
                }
                this.value = [];
                this.$emit('changeSearch',this.form)
            }
        }
    }
</script>

<style scoped>
    .search {
        display: flex;
        flex-wrap: wrap;
    }
    .search > .el-input {
        width: 300px;
    }
    .search > .el-cascader {
        width: 300px;
    }
    .margin{
        margin-bottom: 10px;
        margin-right: 10px;
    }
</style>
