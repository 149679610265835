import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '产品型号',
            prop: 'productModel'
        },
        {
            label: '主图封面',
            prop: 'mainCover',
            type: 'img'
        },
        {
            label: '型号描述',
            prop: 'modelDesc'
        },
        {
            label: '分类',
            prop: 'oneClassifyName'
        },
        {
            label: 'FT/NF总码数',
            prop: 'ftTotalYards'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ],
    increaseColumn: [
        {
            label: '产品型号',
            prop: 'productModel',
            span: 12,
            row: true,
            maxlength: 50,
            rules: [{
                required: true,
                message: "输入产品型号",
                trigger: "blur"
            }],
            disabled: false
        },
        {
            label: '型号描述',
            prop: 'modelDesc',
            span: 12,
            row: true,
            maxlength: 200,
            // rules: [{
            //     required: true,
            //     message: "输入型号描述",
            //     trigger: "blur"
            // }]
        },
        {
            label:'产品分类',
            prop:'region',
            span: 15,
        },
        {
            label:'主图封面',
            prop:'mainCover',
            type: 'upload',
            accept: 'image/*',
            span: 24,
            row: true,
            listType: 'picture-img',
            fileSize: 1024,
            propsHttp: {
                res:'data',
                url: 'url',
                name: 'path'
            },
            tip: '说明：商品列表中展示，只上传一张，图片格式JPG、PNG、JEPG，大小1M以内（建议尺寸113*133，可按比例扩大缩小）',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            rules: [{
                required: true,
                message: "上传主图封面",
                trigger: "blur"
            }]
        },
        {
            label:'Banner图',
            prop:'bannerImage',
            type: 'upload',
            accept: 'image/*',
            span: 24,
            row: true,
            listType: 'picture-card',
            dataType: 'string',
            limit: 6,
            fileSize: 10240,
            propsHttp: {
                res:'data',
                url: 'url',
                name: 'path'
            },
            tip: '说明：商品轮播图，图片格式JPG、PNG、JEPG，大小10M以内。最多可以上传6张（建议尺寸375*375，可按比例扩大缩小）',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            rules: [{
                required: true,
                message: "上传主图封面",
                trigger: "blur"
            }]
        },
        {
            label: '卷号详情',
            prop: 'detail',
            span: 24
        }
    ],
    crudOptionA: [
        {
            label: '等级',
            prop: 'productGrade'
        },
        {
            label: '卷号',
            prop: 'reelNumber'
        },
        {
            label: '码长',
            prop: 'codeLength'
        },
        {
            label: '收货日期',
            prop: 'receiveTime'
        }
    ],
    crudOptionB: [
        {
            label: '等级',
            prop: 'productGrade'
        },
        {
            label: '卷号',
            prop: 'reelNumber'
        },
        {
            label: '码长',
            prop: 'codeLength'
        }
    ],
    columnIncreaseNumber: [
        {
            label: '仓库',
            prop: 'warehouse',
            type: 'select',
            dicData: [
                {
                    label: 'FT/NF',
                    value: 'FT/NF'
                },
                {
                    label: 'IS',
                    value: 'IS'
                }
            ],
            span: 16,
            row: true,
            placeholder: '请选择仓库',
            rules: [{
                required: true,
                message: "选择仓库",
                trigger: "blur"
            }]
        },
        {
            label: '等级',
            prop: 'productGrade',
            type: 'select',
            dicData: [
                {
                    label: 'A级',
                    value: 'A'
                },
                {
                    label: 'B级',
                    value: 'B'
                },
                {
                    label: 'C级',
                    value: 'C'
                }
            ],
            span: 16,
            row: true,
            placeholder: '请选择等级',
            rules: [{
                required: true,
                message: "选择等级",
                trigger: "blur"
            }]
        },
        {
            label: '卷号',
            prop: 'reelNumber',
            span: 16,
            row: true,
            maxlength: 20,
            rules: [{
                required: true,
                message: "输入卷号",
                trigger: "blur"
            }]
        },
        {
            label: '码长',
            prop: 'codeLength',
            span: 16,
            row: true,
            maxlength: 10,
            rules: [{
                required: true,
                message: "输入码长",
                trigger: "blur"
            }]
        },
        {
            label: '收货日期',
            prop: 'receiveTime',
            span: 16,
            row: true,
            slot: true,
            display: true
        }

    ],
    relatedGoods: [
        {
            label: '产品型号',
            prop: 'productModel'
        },
        {
            label: '主图封面',
            prop: 'mainCover',
            type: 'img'
        },
        {
            label: '型号描述',
            prop: 'modelDesc'
        },
        {
            label: '分类',
            prop: 'classifyName'
        },
        {
            label: '关联时间',
            prop: 'createTime'
        }
    ],
    choiceGoods: [
        {
            label: '产品型号',
            prop: 'productModel'
        },
        {
            label: '主图封面',
            prop: 'mainCover',
            type: 'img'
        },
        {
            label: '型号描述',
            prop: 'modelDesc'
        },
        {
            label: '分类',
            prop: 'classifyName'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ]
}
export default column;
