<template>
    <div class="search">
<!--        <el-input v-model="form.reelNumber" placeholder="请输入卷号" class="margin"></el-input>-->
        <input type="text" class="input margin" v-model="form.reelNumber" placeholder="请输入卷号">
        <el-button type="primary" @click="searchQuery" style="margin-bottom: 10px">查询</el-button>
        <el-button type="info" @click="searchReset" style="margin-bottom: 10px">重置</el-button>
    </div>
</template>

<script>
    export default {
        name: "increasedSearch",
        data(){
            return{
                form: {
                    reelNumber: ''
                }
            }
        },
        methods: {
            searchQuery(){
                this.$emit('changeIncreasedSearch',this.form)
            },
            searchReset(){
                this.form = {
                    reelNumber: ''
                }
                this.$emit('changeIncreasedSearch',this.form)
            }
        }
    }
</script>

<style scoped>
    .search {
        display: flex;
        flex-wrap: wrap;
    }
    .search > .el-input {
        width: 300px;
    }
    .input{
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        outline: 0;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 300px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
    }
    .margin{
        margin-bottom: 10px;
        margin-right: 10px;
    }
</style>
