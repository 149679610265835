<template>
    <div class="main-content">
        <pagesHeader title="设置关联商品" />
        <div class="content_info">
        <el-descriptions title="当前商品信息" :column="1">
            <el-descriptions-item label="型号">{{info.productModel}}</el-descriptions-item>
            <el-descriptions-item label="描述">{{info.modelDesc}}</el-descriptions-item>
            <el-descriptions-item label="主图封面">
                <el-image style="width: 100px; height: 100px"
                        :src="info.mainCover"
                        :preview-src-list="covers"
                        fit="scale-down"></el-image>
            </el-descriptions-item>
            <el-descriptions-item label="分类">
                {{info.oneClassifyName}}{{info.twoClassifyName ? `/${info.twoClassifyName}` : ''}}{{info.threeClassifyName ? `/${info.threeClassifyName}` : ''}}
            </el-descriptions-item>
        </el-descriptions>
        </div>
        <div class="content_info">
            <div class="content_info_header">
                <div class="content_info_header_title">关联产品信息</div>
                <el-button type="primary" @click="increased">添加关联产品</el-button>
            </div>
            <avue-crud ref="crud"
                       :data="data"
                       :option="option"
                       :page.sync="pageSync"
                       @on-load="onLoad">
                <template slot="classifyName" slot-scope="{ row }">
                    <div>{{row.oneClassifyName}}{{row.twoClassifyName ? `/${row.twoClassifyName}` : ''}}{{row.threeClassifyName ? `/${row.threeClassifyName}` : ''}}</div>
                </template>

                <template slot="menu" slot-scope="{ row }">
                    <el-button size="small" type="text" @click="changeDelete(row.id)">删除</el-button>
                </template>

            </avue-crud>
        </div>
        <ChoiceGoods v-if="choiceGoods.type" :choiceGoods="choiceGoods" />
    </div>
</template>

<script>
    import column from "./column";
    import ChoiceGoods from "./choiceGoods";
    import { manageView,manageRelationList,manageRelationDelete } from "@/api/product/manage"
    export default {
        name: "relatedGoods",
        components: {
            ChoiceGoods
        },
        data(){
            return{
                info: {},
                covers: [],
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    index: true,
                    indexLabel: '序号',
                    indexWidth: 100,
                    menuWidth: 150,
                    column: column.relatedGoods
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                choiceGoods: {
                    type: false,
                    id: this.$route.params.id
                }
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeChoiceGoods', (n)=>{
                this.choiceGoods.type = false;
                if(n){
                    this.getManageRelationList();
                }
            })


            this.getManageView();
        },
        methods: {
            getManageView(){
                manageView(this.$route.params.id).then(res=>{
                    console.log(res)
                    if(res.code == 200){
                        this.info = res.data;
                        this.covers = res.data.mainCover.split(',')
                    }
                })
            },
            increased(){
                this.choiceGoods.type = true
            },
            onLoad(){
                this.getManageRelationList();
            },
            getManageRelationList(){
                manageRelationList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    id: this.$route.params.id
                }).then(res=>{
                    // console.log(res)
                    if(res.code == 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeDelete(id){
                this.$confirm("是否删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    manageRelationDelete(id).then(res=>{
                        if(res.code === 200){
                            this.$message.success(res.msg)
                            this.getManageRelationList();
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                    })
                });
            }
        }
    }
</script>

<style scoped>
.content_info{
    padding: 20px;
    border: 1px solid #797979;
    margin-bottom: 20px;
}
    /deep/ .el-descriptions-item__container{
        align-items: center;
    }
    .content_info_header{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .content_info_header_title{
        font-size: 16px;
        color: #303133;
        font-weight: 700;
        margin-right: 20px;
    }
</style>
