const column = {
    column: [
        {
            label: '分类名称',
            prop: 'name'
        }
    ],
    columnIncrease: [
        {
            label: '分类名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '最多输入15个字',
            rules: [{
                required: true,
                message: "输入分类名称",
                trigger: "blur"
            }],

        },
        {
            label: '排序值',
            prop: 'sort',
            span: 24,
            row: true,
            maxlength: 6,
            placeholder: '最多输入6个字',
            rules: [{
                required: true,
                message: "输入排序值",
                trigger: "blur"
            }],

        },
        {
            label: '是否显示',
            prop: 'isShow',
            type: 'radio',
            dicData: [
                {
                    label: '显示',
                    value: 1
                },
                {
                    label: '不显示',
                    value: 2
                }
            ],
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "选择是否显示",
                trigger: "blur"
            }],

        }
    ]
}
export default column
