<template>
    <div class="main-content">
        <pagesHeader title="隐私政策" />
        <avue-form ref="form" v-model="form" :option="option">
            <template slot="content">
                <avue-ueditor v-model="form.content" :options="uOptions"></avue-ueditor>
            </template>
            <template slot="menuForm">
                <div class="submit-button">
                    <el-button type="primary" size="medium" @click="handleSubmit(form)">保存</el-button>
                </div>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import { actionUrl } from '@/config/public';
    import { privacyView,privacyUpdate } from "@/api/platform/privacy"
    export default {
        name: "integral",
        data(){
            return{
                form: {
                    content: ''
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: [
                        {
                            label: '隐私政策',
                            span: 24,
                            prop: 'content',
                            slot: true,
                            rules: [{
                                required: true,
                                message: '请输入隐私政策',
                                trigger: 'blur'
                            }]
                        }
                    ]
                },
                uOptions: {
                    action: actionUrl,
                    headers: {
                        Authorization: this.$store.state.user.Token
                    },
                    customConfig: {},
                    props: {
                        url: 'path',
                        res: 'data',
                        name: 'path'
                    }
                },
            }
        },
        mounted() {
            this.GET_integralView();
        },
        methods: {
            handleSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        privacyUpdate({
                            id: form.id,
                            content: form.content
                        }).then(res=>{
                            if(res.code === 200){
                                this.$message.success(res.msg)
                            }else{
                                this.$message.error(res.msg)
                            }
                            done();
                        }).catch(err=>{
                            this.$message.error(err.msg)
                            done();
                        })
                    }
                })
            },
            GET_integralView(){
                privacyView().then(res=>{
                    if(res.code === 200){
                        this.form = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            }
        }
    }
</script>

<style scoped>

</style>
