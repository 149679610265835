<template>
    <div class="main-content">
		<pagesHeader title="角色管理" />
        <div class="table-content">
            <avue-crud :data="data"
                       :option="option"
                       :search.sync="search"
                       :page.sync="pageSync"
                       @on-load="onLoad"
                       @search-change="searchChange"
                       @search-reset="searchReset"
                       ref="crud">
                <template slot="menuLeft">
                    <el-button type="primary"
                               icon="el-icon-plus"
                               size="small"
                               @click.stop="clickMenu('add')">添加角色</el-button>
                </template>

                <template slot="menu" slot-scope="{row}">
                    <el-button type="text"
                               size="small"
                               @click.stop="clickMenu('edit',row.id)">编辑</el-button>
                    <el-button type="text"
                               size="small"
                               @click.stop="clickMenu('authorize',row.id)">授权</el-button>
                    <el-button type="text"
                               style="margin-left:10px;"
                               size="small"
                               @click.stop="clickMenu('cel',row.id)">删除</el-button>
                </template>
            </avue-crud>
        </div>

        <roleIndex v-if="dialogVisible" :dialogVisible="dialogVisible" :choiceId="choiceId" :title="title"/>

        <rolePower v-if="dialogVisiblePower" :dialogVisible="dialogVisiblePower" :choiceId="choiceId" :tabs="tabs"/>
    </div>
</template>

<script>
    import { roleList,roleDelete,roleMenuTree } from "@/api/system/role";
    import roleIndex from '@/components/system/role/index';
    import rolePower from '@/components/system/role/power';
    export default {
        name: "list",
        components:{
            roleIndex,
            rolePower
        },
        data() {
            return {
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                Buttons: [],
                search:{},
                data: [],
                option:{
                    border:true,
                    stripe:false,
                    showHeader:true,
                    align:'center',
                    menuAlign:'center',
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    saveBtn:false,
                    updateBtn:false,
                    cancelBtn:false,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    menuWidth: 300,
                    column:[
                        {
                            label:'识别码',
                            prop:'code'
                        },
                        {
                            label:'名称',
                            prop:'name'
                        },
                        {
                            label:'类型',
                            prop:'remarks'
                        },
                        {
                            label:'状态',
                            prop:'state',
							type: 'radio',
							dicData: [{label: '启用',value: 1},{label: '禁用',value: 2}],
                        },
                        {
                            label:'创建人',
                            prop:'createBy'
                        },
                        {
                            label:'创建时间',
                            prop:'createTime'
                        },
                        {
                            label:'最后修改时间',
                            prop:'updateTime'
                        }
                    ]
                },

                title: '',
                choiceId: '',
                dialogVisible: false,
                dialogVisiblePower: false,
                ids: [],
                name: '', // 请求false

                // 角色权限管理
                tabs: []
            };
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons

            this.$root.eventHub.$on('changeRole', (n)=>{
                this.dialogVisible = false;
                if(n){
                    this.role_list();
                }
            })

            this.$root.eventHub.$on('changeRolePower', (n)=>{
                this.dialogVisiblePower = n;
            })
        },
        methods:{
            searchChange(params,done) {
                this.name = params.name;
                this.role_list();
                done();
            },
            searchReset(params){
                this.name = params.name;
                this.role_list();
            },
            onLoad(){
                this.role_list()
            },
            role_list(){
                roleList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.name
                }).then(res => {
                    this.data = res.data.records;
                    this.pageSync.total = res.data.total;
                    this.pageSync.currentPage = res.data.current;
                    if(res.data.current > res.data.pages){
                        this.pageSync.currentPage = res.data.pages;
                        this.role_list();
                    }
                }).catch(err =>{
                    this.$message.error(err.msg)
                })
            },
            clickMenu(name,id){
                if(name == 'cel'){
                    this.UserDelete(id)
                }else if(name == 'authorize'){
                    this.choiceId = id;
                    this.roleTreeMenu();
                }else{
                    this.title = name;
                    // console.log('title',name)
                    this.dialogVisible = true;
                    this.choiceId = id;
                }
            },
            roleTreeMenu(){
                roleMenuTree().then(res => {
                    if(res.code === 200){
                        this.tabs = res.data;
                        this.dialogVisiblePower = true;
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(err => {
                    this.$message.error(err.msg);
                })
            },
            UserDelete(id){
                this.$confirm(`删除这条数据`, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        if(action == 'confirm'){
                            roleDelete(id).then(res => {
                                // 获取数据成功后的其他操作
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.role_list();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            }).catch(err =>{
                                this.$message.error(err.msg)
                            })
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
