const column = {
    column: [
        {
            label: '手机号',
            prop: 'phone'
        },
        {
            label: '描述',
            prop: 'whiteDesc'
        },
        {
            label: '最后编辑日期',
            prop: 'updateTime'
        }
    ]
}
export default column
