import {Delete, get, post, put} from "../axios";

// 分类管理-列表
export const classifyList = params => get(`/web/product/classify/list`,params);

// 分类管理-新增
export const classifyAdd = params => post(`/web/product/classify/add`,params);

// 分类管理-删除
export const classifyDelete = params => Delete(`/web/product/classify/delete/${params}`);

// 分类管理-查看
export const classifyView = params => get(`/web/product/classify/view/${params}`);

// 分类管理-修改
export const classifyUpdate = params => put(`/web/product/classify/update`,params);
