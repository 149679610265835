import {Delete, get, post, put} from "../axios";

// 产品管理-列表
export const manageList = params => get(`/web/product/manage/list`,params);

// 产品管理-删除
export const manageDelete = params => Delete(`/web/product/manage/delete/${params}`);

// 产品管理-查看
export const manageView = params => get(`/web/product/manage/view/${params}`);

// 产品管理-更新
export const manageUpdate = params => put(`/web/product/manage/update`,params);

// 产品管理-新增
export const manageAdd = params => post(`/web/product/manage/add`,params);

// 产品管理-新增-id获取
export const manageId = params => get(`/web/product/manage/id/get`,params);

// 产品管理-新增-仓库列表
export const manageReelList = params => get(`/web/product/manage/reel/list`,params);

// 产品管理-新增-添加卷号
export const manageReelAdd = params => post(`/web/product/manage/reel/add`,params);

// 产品管理-新增-卷号删除
export const manageReelDelete = params => Delete(`/web/product/manage/reel/delete/${params}`);

// 产品管理-列表-关联产品-列表
export const manageRelationList = params => get(`/web/product/manage/relation/list`,params);

// 产品管理-列表-关联产品-列表-选择
export const manageRelationSelectList = params => get(`/web/product/manage/relation/select/list`,params);

// 产品管理-列表-关联产品-新增
export const manageRelationAdd = params => post(`/web/product/manage/relation/add`,params);

// 产品管理-列表-关联产品-删除
export const manageRelationDelete = params => Delete(`/web/product/manage/relation/delete/${params}`);

// 产品管理-列表-产品管理-列表-分类
export const classifyManageList = params => get(`/web/product/manage/list/classify`,params);

// 产品管理-列表-产品管理-列表-分类
export const classifyManageAdd = params => get(`/web/product/manage/add/classify`,params);

// 产品管理-分类获取
export const manageClassify = params => get(`/web/product/manage/classify/${params}`);
