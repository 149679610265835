<template>
  <el-dialog
    title="编辑"
    :visible.sync="dialogVisible"
    width="60%"
    v-dialogdrag
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-before="uploadBefore"
      :upload-after="uploadAfter"
    >
      <template slot="role">
      <el-select v-model="form.role" multiple placeholder="请选择">
        <el-option
                v-for="item in role"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.state == 2 ? true : false">
        </el-option>
      </el-select>
    </template>
      <template slot="phone">
        <input
          class="el-input__inner"
          style="height: 32px"
          placeholder="请输入 联系电话"
          maxlength="11"
          type="tel"
          v-model="form.phone"
          pattern="[0-9]"
          onkeyup="value=value.replace(/[\W]/g,'')"
          onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
        />
      </template>
    </avue-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        icon="el-icon-circle-check"
        type="primary"
        size="small"
        @click="handleSubmit(form)"
        >修 改</el-button
      >
      <el-button icon="el-icon-circle-close" size="small" @click="handleClose"
        >取 消</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { imageUrl, actionUrl } from "@/config/public";
import { userView, userUpdate } from "@/api/system/index";
export default {
  name: "edit",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    choiceId: String,
  },
  data() {
    return {
      role: this.$store.state.system.role,
      form: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "头像",
            type: "upload",
            listType: "picture-img",
			tip: '只能上传jpg/png',
            span: 14,
            row: true,
            prop: "headPortrait",
            loadText: "附件上传中，请稍等",
            propsHttp: {
              res: "data",
              url: "url",
              name: "path",
              home: imageUrl,
            },
            headers: {
              Authorization: this.$store.state.user.Token,
            },
            action: actionUrl,
            rules: [
              {
                required: true,
                message: "请上传头像",
                trigger: "blur",
              },
            ],
          },
          {
            label: "昵称",
            prop: "fullName",
            rules: [
              {
                required: true,
                message: "请输入昵称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "联系电话",
            prop: "phone",
            slot: true,
            rules: [
              {
                required: true,
                message: "请输入联系电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "所属角色",
            prop: "role",
            type: "select",
            dicData: this.$store.state.system.role,
            span: 12,
            multiple: true,
            props: {
              label: "name",
              value: "id",
            },
          },
          {
            label: "状态",
            prop: "state",
            type: "radio",
            dicData: [
              { label: "启用", value: 1 },
              { label: "禁用", value: 2 },
            ],
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    userView(this.choiceId).then((res) => {
      this.form = res.data;
      // console.log(res.data)
    });
  },
  methods: {
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done) {
      //如果你想修改file文件,由于上传的file是只读文件，必须复制新的file才可以修改名字，完后赋值到done函数里,如果不修改的话直接写done()即可
      var newFile = new File([file], file.name, { type: file.type });
      done(newFile);

      // this.$message.success('上传前的方法')
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.headPortrait = res.image;
      this.$nextTick(() => {
        this.form.image = res.image;
        this.form.headPortrait = res.path;
      });
    },
    handleClose() {
      // this.$confirm("确认关闭？").then((res) => {
        this.$root.eventHub.$emit("changeEdit", false);
      // });
    },
    handleSubmit(form) {
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.phone)) {
        this.$message.error('手机号错误')
        return false;
      }
      userUpdate({
        id: form.id,
        fullName: form.fullName,
        headPortrait: form.headPortrait,
        phone: form.phone,
        state: form.state,
        roleIds: form.role.toString(),
      }).then((res) => {
        // 获取数据成功后的其他操作
        // console.log(res.data);
        if (res.code === 200) {
          this.$message.success("修改成功");
          this.$root.eventHub.$emit("changeEdit", true);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
