<template>
    <div class="main-content">
        <pagesHeader title="分类管理" />
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="primary" size="small" @click="classification(0,1)">添加分类</el-button>
            </template>

            <template slot="menu" slot-scope="{ row }">
                <el-button size="small" type="text" @click="changeEdit(row.id,row.classifyLevel,row.parentId)">编辑</el-button>
                <el-button v-if="row.classifyLevel == 1 || row.classifyLevel == 2" size="small" type="text" @click="classification(row.id,row.classifyLevel + 1)">添加分类</el-button>
                <el-button v-if="row.children != null" size="small" type="text" style="color: #AAAAAA">删除</el-button>
                <el-button v-else size="small" type="text" @click="changeDelete(row.id)">删除</el-button>
            </template>

        </avue-crud>

        <Increased v-if="classifyIncreased.type" :classifyIncreased="classifyIncreased"></Increased>
    </div>
</template>

<script>
    import column from "./column"
    import Increased from "./increased"
    import { classifyList,classifyDelete } from "@/api/product/classify"
    export default {
        name: "classify",
        components: {
            Increased
        },
        data(){
            return{
                data: [],
                option: {
                    align: 'left',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    border: true,
                    menuWidth: 400,
                    column: column.column
                },
                classifyIncreased: {
                    type: false,
                    id: '0',
                    parentId: '0',
                    classifyLevel: 0
                }
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeProductClassify', (n)=>{
                this.classifyIncreased = {
                    type: false,
                    id: '0',
                    parentId: '0',
                    classifyLevel: 0
                }
                if(n){
                    this.getClassifyList();
                }
            })
        },
        methods: {
            onLoad(){
                this.getClassifyList();
            },
            getClassifyList(){
                classifyList().then(res=>{
                    // console.log(res)
                    if(res.code == 200){
                        this.data = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            classification(id,level){
                // console.log('添加分类',id,level)
                this.classifyIncreased = {
                    type: true,
                    id: '0',
                    parentId: id,
                    classifyLevel: level
                }
            },
            changeEdit(id,level,parentId){
                // console.log('编辑分类',id,level)
                this.classifyIncreased = {
                    type: true,
                    id: id,
                    parentId: parentId,
                    classifyLevel: level
                }
            },
            changeDelete(id){
                // console.log('删除分类')
                this.$confirm('删除分类, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    classifyDelete(id).then(res=>{
                        if(res.code == 200){
                            // console.log(res)
                            this.$message.success(res.msg)
                            this.getClassifyList();
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                    })
                }).catch(() => {
                });

            }
        }
    }
</script>

<style scoped>

</style>
