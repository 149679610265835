<template>
    <div class="search">
        <el-input v-model="form.productModel" placeholder="请输入型号名称" class="margin"></el-input>
        <el-button type="primary" @click="searchQuery" style="margin-bottom: 10px">查询</el-button>
        <el-button type="info" @click="searchReset" style="margin-bottom: 10px">重置</el-button>
    </div>
</template>

<script>
    export default {
        name: "choiceGoodsSearch",
        data(){
            return{
                form:{
                    productModel: ''
                }
            }
        },
        methods: {
            searchQuery(){
                this.$emit('changeChoiceGoodsSearch',this.form)
            },
            searchReset(){
                this.form = {
                    productModel: ''
                }
                this.$emit('changeChoiceGoodsSearch',this.form)
            }
        }
    }
</script>

<style scoped>
    .search {
        display: flex;
        flex-wrap: wrap;
    }
    .search > .el-input {
        width: 300px;
    }
    .margin{
        margin-bottom: 10px;
        margin-right: 10px;
    }
</style>
