<template>
    <div class="main-content">
        <pagesHeader :title="$route.params.id == '0' ? '添加' : '编辑'" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete"
                   :upload-after="uploadAfter">

            <template slot="detail">
                <el-button style="margin-bottom: 10px" @click="changeNumber">添加卷号</el-button>
                <IncreasedSearch @changeIncreasedSearch="changeIncreasedSearch"></IncreasedSearch>

                <div class="nav_button">
                    <el-button @click="changeButton('A')" style="margin-bottom: 10px" :type="primary == 'A' ? 'primary' : ''">A级</el-button>
                    <el-button @click="changeButton('B')" style="margin-bottom: 10px" :type="primary == 'B' ? 'primary' : ''">B级</el-button>
                    <el-button @click="changeButton('C')" style="margin-bottom: 10px" :type="primary == 'C' ? 'primary' : ''">C级</el-button>
                </div>
                <div class="nav_table">
                    <div class="nav_label">FT/NF仓库</div>
                    <avue-crud ref="crud"
                               :data="crudDataA"
                               :option="crudOptionA"
                               :page.sync="crudPageSyncA"
                               @on-load="onLoad">

                        <template slot="menu" slot-scope="{ row }">
                            <el-button size="small" type="text" @click="changeDelete(row.id,'a')">删除</el-button>
                        </template>
                    </avue-crud>
                </div>
                <div class="nav_table">
                    <div class="nav_label">IS仓库</div>
                    <avue-crud ref="crud"
                           :data="crudDataB"
                           :option="crudOptionB"
                           :page.sync="crudPageSyncB"
                           @on-load="onLoad">

                        <template slot="menu" slot-scope="{ row }">
                            <el-button size="small" type="text" @click="changeDelete(row.id,'b')">删除</el-button>
                        </template>
                    </avue-crud>
                </div>


            </template>
          <template slot="region">
            <div style="display: flex">
              <el-select  clearable v-model="form.oneClassifyId" placeholder="一级分类">
                <el-option  v-for="item in oneClassifyName" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select clearable v-model="form.twoClassifyId" placeholder="二级分类">
                <el-option v-for="item in twoClassifyName" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select clearable v-model="form.threeClassifyId" placeholder="三级分类">
                <el-option v-for="item in threeClassifyName" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </template>

            <template slot="menuForm">
                <div class="submit-button">
                    <el-button size="medium" @click="goReturn">取消</el-button>
                    <el-button type="primary" size="medium" @click="handleSubmit(form)">确认提交</el-button>
                </div>
            </template>
        </avue-form>

        <IncreasedNumber v-if="increasedNumber.type" :increasedNumber="increasedNumber"/>
    </div>
</template>

<script>
    import column from "./column"
    import IncreasedSearch from "./increasedSearch"
    import IncreasedNumber from "./increasedNumber"
    import { manageId,manageReelList,manageReelDelete,manageAdd,manageView,manageUpdate,classifyManageAdd,manageClassify } from "@/api/product/manage"
    export default {
        name: "increased",
        components: {
            IncreasedSearch,
            IncreasedNumber
        },
        data(){
            return{
                form: {
                    productId: '',
                    detail: 'detail'
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.increaseColumn
                },
                crudDataA: [],
                crudOptionA: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    column: column.crudOptionA
                },
                crudPageSyncA: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                crudDataB: [],
                crudOptionB: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    column: column.crudOptionB
                },
                crudPageSyncB: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                primary: 'A',
                increasedSearch: {
                    reelNumber: '', //卷号
                },
                increasedNumber: {
                    type: false,
                    productId: '',
                },
                classifyList: [],
                viewType: false,
                oneClassifyName: [],
                twoClassifyName: [],
                threeClassifyName: [],
                oneClassifyId: '',
                twoClassifyId: '',
            }
        },
        watch:{
            'form.productId'(value){
                console.log('form.productId',value)
            },
            'increasedNumber.productId'(value){
                console.log('increasedNumber.productId',value)
            },
          'form.oneClassifyId'(value){
              // console.log(value)
            if(value != ''){
              this.oneClassifyName.forEach(item=>{
                if(item.id === value){
                  this.form.oneClassifyName = item.name
                }
              })
              if(this.oneClassifyId != value){
                if(this.form.twoClassifyId != '' && this.form.twoClassifyId != undefined){
                  this.form.twoClassifyId = '';
                  this.form.twoClassifyName = '';
                  if(this.form.threeClassifyId != '' && this.form.twoClassifyId != undefined){
                    this.form.threeClassifyId = '';
                    this.form.threeClassifyName = '';
                    this.form.region = '';
                  }
                }
                this.oneClassifyId = value;
              }
              this.GET_GetArea(value,'twoClassifyName');
            }else {
              this.form.twoClassifyId = '';
              this.form.twoClassifyName = '';
              this.form.threeClassifyId = '';
              this.form.threeClassifyName = '';
            }
          },
          'form.twoClassifyId'(value){
            if(value != ''){
              this.twoClassifyName.forEach(item=>{
                if(item.id === value){
                  this.form.twoClassifyName = item.name
                }
              })
              if(this.twoClassifyId != value){
                if(this.form.threeClassifyId != '' && this.form.threeClassifyId != undefined){
                  this.form.threeClassifyId = '';
                  this.form.threeClassifyName = '';
                  this.form.region = '';
                }
                this.twoClassifyId = value;
              }
              this.GET_GetArea(value,'threeClassifyName');
            }else {
              this.form.threeClassifyId = '';
              this.form.threeClassifyName = '';
            }
          },
          'form.threeClassifyId'(value){
            if(value != ''){
              this.threeClassifyName.forEach(item=>{
                if(item.id === value){
                  this.form.threeClassifyName = item.name;
                  this.form.region = item.name;
                }
              })
            }
          }
        },
        mounted() {
            this.GET_GetArea('0','oneClassifyName');
            const mainCover = this.findObject(this.option.column, 'mainCover');
            mainCover.headers.Authorization = this.$store.state.user.Token;
            const bannerImage = this.findObject(this.option.column, 'bannerImage');
            bannerImage.headers.Authorization = this.$store.state.user.Token;
            const productModel = this.findObject(this.option.column, 'productModel');
            let id = this.$route.params.id;
            if(id == 0){
                this.getManageId();
                productModel.disabled = false;
            }else{
                this.viewType = true;
                this.getManageView();
                productModel.disabled = true;
            }

            this.getClassifyList();


            this.$root.eventHub.$on('changeIncreasedNumber', (n)=>{
                this.increasedNumber.type = false;
                console.log(n)
                if(n.type){
                    if(n.e == 'a'){
                        console.log('this.getManageReelList','a1')
                        this.getManageReelList('a',this.crudPageSyncA);
                    }else{
                        console.log('this.getManageReelList','b2')
                        this.getManageReelList('b',this.crudPageSyncB);
                    }
                }
            })
        },
        methods: {
            getManageView(){
                manageView(this.$route.params.id).then(res=>{
                    // console.log(res)
                    if(res.code == 200){
                        this.form = res.data;
                      this.oneClassifyId = res.data.oneClassifyId;
                      this.twoClassifyId = res.data.twoClassifyId;
                      this.form.region = res.data.threeClassifyId;
                        this.form.detail =  'detail';
                        this.increasedNumber.productId = res.data.productId;
                        this.getManageReelList('a',this.crudPageSyncA);
                        this.getManageReelList('b',this.crudPageSyncB);
                        setTimeout(()=>{
                            this.viewType = false;
                        },1000)
                    }
                })
            },
            getClassifyName(list){

                list.forEach(item=>{
                    if(item.id == this.form.oneClassifyId){
                        this.form.oneClassifyName = item.name;
                    }
                    if(item.id == this.form.twoClassifyId){
                        this.form.twoClassifyName = item.name;
                    }
                    if(item.id == this.form.threeClassifyId){
                        this.form.threeClassifyName = item.name;
                    }
                    if(item.children != undefined){
                        this.getClassifyName(item.children);
                    }
                })
            },
            //产品管理-新增-id获取
            getManageId(){
                manageId().then(res=>{
                    if(res.code === 200){
                        this.form.productId = res.data.productId;
                        this.increasedNumber.productId = res.data.productId;
                    }
                })
            },
            changeNumber(){
                this.increasedNumber.type = true;
            },
            changeButton(e){
               this.primary = e;
                this.crudPageSyncA.currentPage = 1;
                this.crudPageSyncB.currentPage = 1;
                this.getManageReelList('a',this.crudPageSyncA);
                this.getManageReelList('b',this.crudPageSyncB);
            },
            changeDelete(id,e){
                this.$confirm("是否删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    manageReelDelete(id).then(res=>{
                        if(res.code === 200){
                            this.$message.success(res.msg)
                            if(e == 'a'){
                                this.getManageReelList('a',this.crudPageSyncA);
                            }else{
                                this.getManageReelList('b',this.crudPageSyncB);
                            }
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                    })
                });
            },
            handleSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.$route.params.id == '0'){
                            this.getManageAdd(form,done)
                        }else{
                            this.getManageUpdate(form,done)
                        }
                    }
                })
            },
          GET_GetArea(e,type){
            manageClassify(e).then(res=>{
              // console.log(res)
              if(res.code === 200){
                if(type === 'oneClassifyName'){
                  this.oneClassifyName = res.data;
                }else if(type === 'twoClassifyName'){
                  this.twoClassifyName = res.data;
                }else{
                  this.threeClassifyName = res.data;
                }
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err=>{
              this.$message.error(err.msg)
            })
          },
            getManageAdd(form,done){
                manageAdd({
                    productId: form.productId,  //产品id，通过接口获取到的
                    productModel: form.productModel, //产品型号
                    modelDesc: form.modelDesc, //型号描述
                    mainCover: form.mainCover, //主图封面
                    bannerImage: form.bannerImage == '' ? '' : form.bannerImage.split(',').filter(Boolean).toString(), //banner图
                    oneClassifyId: form.oneClassifyId, //一级分类id
                    oneClassifyName: form.oneClassifyName, //一级分类名称
                    twoClassifyId: form.twoClassifyId, //二级分类id
                    twoClassifyName: form.twoClassifyName, //二级分类名称
                    threeClassifyId: form.threeClassifyId, //三级分类id
                    threeClassifyName: form.threeClassifyName
                }).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err =>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            getManageUpdate(form,done){
                manageUpdate({
                    id: form.id,
                    productId: form.productId,  //产品id，通过接口获取到的
                    productModel: form.productModel, //产品型号
                    modelDesc: form.modelDesc, //型号描述
                    mainCover: form.mainCover, //主图封面
                    bannerImage: form.bannerImage == '' ? '' : form.bannerImage.split(',').filter(Boolean).toString(), //banner图
                    oneClassifyId: form.oneClassifyId, //一级分类id
                    oneClassifyName: form.oneClassifyName, //一级分类名称
                    twoClassifyId: form.twoClassifyId, //二级分类id
                    twoClassifyName: form.twoClassifyName, //二级分类名称
                    threeClassifyId: form.threeClassifyId, //三级分类id
                    threeClassifyName: form.threeClassifyName
                }).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err =>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            onLoad(){
                if(this.form.productId != 0){
                    this.getManageReelList('a',this.crudPageSyncA);
                    this.getManageReelList('b',this.crudPageSyncB);
                }
            },
            getManageReelList(e,page){
                if(this.form.productId != 0){
                    manageReelList({
                        page: page.currentPage,
                        size: page.pageSize,
                        productGrade: this.primary,
                        productId: this.form.productId,
                        warehouse: e == 'a' ? 'FT/NF' : 'IS',
                        reelNumber: this.increasedSearch.reelNumber
                    }).then(res=>{
                        // console.log(res)
                        if(res.code == 200){
                            if(e == 'a'){
                                this.crudDataA = res.data.records;
                                this.crudPageSyncA.total = res.data.total;
                                this.crudPageSyncA.currentPage = res.data.current;
                            }else{
                                this.crudDataB = res.data.records;
                                this.crudPageSyncB.total = res.data.total;
                                this.crudPageSyncB.currentPage = res.data.current;
                            }
                        }
                    })
                }
            },
            uploadAfter(res, done,l,column) {
                done();
                this.$nextTick(() => {
                    if(column.prop == 'mainCover'){
                        this.form.mainCover = res.path;
                    }else{
                        if(this.form.bannerImage == ''){
                            this.form.bannerImage = res.path
                        }else{
                            this.form.bannerImage = `${this.form.bannerImage},${res.path}`
                        }
                    }
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error) {
                this.$message.error(error);
                // console.log(error, column)
            },
            changeIncreasedSearch(e){
                // console.log('changeIncreasedSearch',e)
                this.increasedSearch = e;
                this.getManageReelList('a',this.crudPageSyncA);
                this.getManageReelList('b',this.crudPageSyncB);
            },
            getClassifyList(){
              classifyManageAdd().then(res=>{
                    if(res.code == 200){
                        this.classifyList = res.data;

                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            goReturn(){
                this.form.productId = '';
                this.increasedNumber.productId = '';
                this.$router.go(-1);
                this.$root.eventHub.$off('changeIncreasedNumber')
            }
        },
        destroyed(){
            this.form.productId = '';
            this.increasedNumber.productId = '';
            this.$root.eventHub.$off('changeIncreasedNumber')
        }
    }
</script>

<style scoped>
    .submit-button .el-button{
        width: 100px;
    }
/deep/ .el-upload{
    width: 146px;
    height: 146px;
}
/deep/ .avue-upload__avatar{
    width: 146px;
    height: 146px;
}
/deep/ .el-icon-plus{
    width: 146px;
    height: 146px;
    line-height: 146px;
}
    .nav_button .el-button{
        width: 100px;
    }
    .nav_table{
        margin-bottom: 20px;
    }
    .nav_label{
        width: 100px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px 10px 0 0;
        background: #66b1ff;
        color: #ffffff;
    }
</style>
