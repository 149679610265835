import { Delete, get, post, put } from '../axios'

// 白名单管理-列表
export const whiteList = params => get(`/web/platform/white/list`,params);

// 白名单管理-新增
export const whiteAdd = params => post(`/web/platform/white/add`,params);

// 白名单管理-查看
export const whiteView = params => get(`/web/platform/white/view/${params}`);

// 白名单管理-修改
export const whiteUpdate = params => put(`/web/platform/white/update`,params);

// 白名单管理-删除
export const whiteDelete = params => Delete(`/web/platform/white/delete/${params}`);

