<template>
    <el-dialog title="添加卷号"
               width="600px"
               v-dialogdrag
               :visible.sync="increasedNumber.type"
               :close-on-click-modal="false"
               :show-close="false"
               :before-close="handleClose">
        <avue-form :option="option" v-model="form" ref="form">
            <template slot="reelNumber">
                <el-input v-model="form.reelNumber"
                          autocomplete="off"
                          type="text"
                          placeholder="请输入卷号"
                          maxlength="20"
                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                          @blur="salaryChange($event,'reelNumber')"/>
            </template>
            <template slot="codeLength">
                <el-input v-model="form.codeLength"
                          autocomplete="off"
                          type="text"
                          placeholder="请输入码长"
                          maxlength="10"
                          onkeyup="this.value=this.value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          @blur="salaryChange($event,'codeLength')"/>
            </template>
            <template slot="receiveTime">
                <el-date-picker
                        v-model="form.receiveTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                </el-date-picker>
            </template>
        </avue-form>
        <div class="submit_button">
            <el-button class="submit_button_item" @click="handleClose">取消</el-button>
            <el-button class="submit_button_item" type="primary" @click="handleSubmit(form)">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import column from "./column";
    import { manageReelAdd } from "@/api/product/manage"
    export default {
        name: "increasedNumber",
        props:{
            increasedNumber: Object
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 100,
                    column: column.columnIncreaseNumber
                },
                loading: false
            }
        },
        watch: {
            'form.warehouse'(value){
                const receiveTime = this.findObject(this.option.column, 'receiveTime');
                if(value == 'IS'){
                    receiveTime.display = false;
                }else{
                    receiveTime.display = true;
                }
            }
        },
        methods: {
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.loading) return;
                        this.loading = true;
                        manageReelAdd({
                            productId: this.increasedNumber.productId,
                            warehouse: form.warehouse,
                            productGrade: form.productGrade,
                            reelNumber: form.reelNumber,
                            codeLength: form.codeLength,
                            receiveTime: form.receiveTime,
                        }).then(res=>{
                            // console.log(res)
                            if(res.code == 200){
                                this.$root.eventHub.$emit('changeIncreasedNumber', {e: form.warehouse == 'IS' ? 'b' : 'a',type: true});
                                this.$message.success(res.msg)
                            }else{
                                this.$message.error(res.msg)
                                this.loading = false;
                            }
                            done();
                        }).catch(err=>{
                            this.$message.error(err.msg)
                            this.loading = false;
                            done();
                        })
                    }
                })
            },
            handleClose(){
                this.$root.eventHub.$emit('changeIncreasedNumber', { type: false });
            }
        }
    }
</script>

<style scoped>
    .submit_button{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .submit_button_item{
        padding: 12px 40px;
    }
</style>
