<template>
    <el-dialog title="商品列表"
               width="1000px"
               v-dialogdrag
               :visible.sync="choiceGoods.type"
               :close-on-click-modal="false"
               :show-close="false"
               :before-close="handleClose">
        <ChoiceGoodsSearch @changeChoiceGoodsSearch="changeChoiceGoodsSearch" />
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
            <template slot="classifyName" slot-scope="{ row }">
                <div>{{row.oneClassifyName}}{{row.twoClassifyName ? `/${row.twoClassifyName}` : ''}}{{row.threeClassifyName ? `/${row.threeClassifyName}` : ''}}</div>
            </template>

            <template slot="menu" slot-scope="{ row }">
                <el-checkbox v-model="row.show" />
            </template>

        </avue-crud>
        <div class="submit_button">
            <el-button class="submit_button_item" @click="handleClose">取消</el-button>
            <el-button class="submit_button_item" type="primary" @click="handleSubmit()">我选好了</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import ChoiceGoodsSearch from "./choiceGoodsSearch"
    import { manageRelationSelectList,manageRelationAdd } from "@/api/product/manage"
    import column from "./column";
    export default {
        name: "choiceGoods",
        components: {
            ChoiceGoodsSearch
        },
        props: {
            choiceGoods: Object
        },
        data(){
            return{
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    index: true,
                    indexLabel: '序号',
                    indexWidth: 100,
                    menuWidth: 150,
                    column: column.choiceGoods
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
                loading: false
            }
        },
        methods:{
            onLoad(){
                this.getManageRelationSelectList();
            },
            getManageRelationSelectList(){
                manageRelationSelectList({
                    id: this.choiceGoods.id,
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    productModel: this.search.productModel,
                }).then(res=>{
                    if(res.code == 200){
                        res.data.records.forEach(item=>{
                            item.show = false;
                        })
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeChoiceGoodsSearch(e){
                // console.log(e)
                this.search = e;
                this.pageSync.currentPage = 1;
                this.getManageRelationSelectList();
            },
            handleSubmit(){
                if(this.loading) return;
                this.loading = true;
                let array = [];
                this.data.forEach(item=>{
                    if(item.show){
                        array.push(item.id)
                    }
                })
                if(array.length > 0){
                    manageRelationAdd({
                        productId: this.choiceGoods.id,
                        relatedProductIds: array.toString()
                    }).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.msg)
                            this.$root.eventHub.$emit('changeChoiceGoods', true);
                        }else{
                            this.$message.error(res.msg)
                            this.loading = false;
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                        this.loading = false;
                    })
                }else{
                    this.$message.error('请选择商品')
                    this.loading = false;
                }

            },
            handleClose(){
                console.log('取消')
                this.$root.eventHub.$emit('changeChoiceGoods', false);
            }
        }
    }
</script>

<style scoped>
    .submit_button{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }
    .submit_button_item{
        padding: 12px 40px;
    }
</style>
